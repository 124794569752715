<template>

  <div class="layout-container">

    <!-- <van-nav-bar @click-left='goback' class='layout-nav-top' :title="title" left-text="返回" left-arrow>
      <template #right>
        <van-icon name="ellipsis" size="18" />
      </template>
    </van-nav-bar>
    <div style="margin-top: 1.2rem;"></div> -->
    <!-- 子路由出口 -->
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
    <!-- /子路由出口 -->

    <!-- 底部导航栏 -->
    <!-- <van-tabbar v-model="active" route> -->
    <!--      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="comment-o" to="/qa">问答</van-tabbar-item>
      <van-tabbar-item icon="video-o" to="/vi">视频</van-tabbar-item>
      <van-tabbar-item icon="manager-o" to="/my">我的</van-tabbar-item> -->
    <!-- </van-tabbar> -->
    <!-- /底部导航栏 -->
  </div>
</template>

<script>
import {
  getToken,
  getTokenForTicket,

} from '@/api/user'
import {
  getItem,
  setItem
} from '@/utils/storage'
export default {
  name: 'LayoutIndex',
  components: {},
  props: {},
  mobile: '',
  data() {
    return {
      active: 0,
      title: '生活权益'
    }
  },
  computed: {},
  watch: {},
  async created() {
    // this.get_token()
  },

  mounted() {
    var that = this
    this.title = this.$route.meta.pagetitle

    this.$router.beforeEach((to, from, next) => {
      that.title = to.meta.pagetitle
      if (this.title == undefined) {
        this.title = '生活权益'
      }
      console.log(to)
      console.log(from)
      next()
    })
    if (this.title == undefined) {
      this.title = '生活权益'
    }


    this.$store.commit('addCachePage', 'LayoutIndex')
  },
  methods: {
    async onload(urlinfo) {
      await this.geticket()
      await this.geticket()


    },

    async geticket(userInfo) {
      let parma = {
        "ticket": userInfo,
      }
      const {
        data
      } = await getTokenForTicket(parma)
      setItem('mobile', data.data)


      // return data.data


    },
    async get_token(mobile = '15028155600') {

      let param = {
        "mobile": mobile
      }
      if (getItem('mobile') != '' && getItem('mobile') != null) {
        param.mobile = getItem('mobile')
      }
      setItem('mobile', param.mobile)
      const {
        data
      } = await getToken(param)

      if (data.code == 0) {

        var dats = {
          token: data.data
        }
        this.$store.dispatch("AddUser", dats)
        // this.$store.commit('setUser', dats)
      }


    },
    goback() {
      console.log(this.$route.path)
      if (this.$route.path === '/') //如果是根目录就不返回
      {

      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}

/deep/ .van-nav-bar__text {
  color: #333333;
}

.layout-nav-top {
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
